import React from "react";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import SensorIcon from "@mui/icons-material/Sensors";
import BatteryIcon from "@mui/icons-material/Battery20";
import ErrorIcon from "@mui/icons-material/Error";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Tasks from "components/Tasks/Tasks.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardFooter from "components/Card/CardFooter.js";

import { website, server } from "variables/general.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <SensorIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Active Sensors</p>
              <h3 className={classes.cardTitle}>92</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                Last 24 Hours
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <BatteryIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Low Battery</p>
              <h3 className={classes.cardTitle}>1</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Danger>
                  <Warning />
                </Danger>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  Replace Battery
                </a>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <ErrorIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Status Errors</p>
              <h3 className={classes.cardTitle}>2</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Danger>
                  <Warning />
                </Danger>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  See More Details
                </a>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomTabs
            title=""
            headerColor="primary"
            tabs={[
              {
                tabName: "Sensor Data",
                tabContent: (
                  <Table
                    tableHeaderColor="warning"
                    tableHead={[
                      "Timestamp",
                      "Sensor",
                      "Building",
                      "Unit",
                      "Status",
                      "Battery",
                    ]}
                    tableData={[
                      [
                        "04/03/21 - 9:32am",
                        "1A - Bathroom",
                        "Alphabet Building",
                        "1A",
                        "Online",
                        "98%",
                      ],
                      [
                        "04/03/21 - 9:32am",
                        "1F - Kitchen",
                        "Alphabet Building",
                        "1F",
                        "Online",
                        "96%",
                      ],
                      [
                        "04/03/21 - 9:32am",
                        "1D - Kitchen",
                        "Alphabet Building",
                        "1D",
                        "Lost Connection",
                        "96%",
                      ],
                      [
                        "04/03/21 - 9:32am",
                        "Floor 1 - Hall",
                        "Bernard Building",
                        "1 - Hallway",
                        "Water Detected",
                        "98%",
                      ],
                      [
                        "04/03/21 - 9:32am",
                        "3H - Bathroom",
                        "Bernard Building",
                        "3H",
                        "Online",
                        "15%",
                      ],
                      [
                        "04/03/21 - 9:32am",
                        "3A - Bathroom",
                        "Bernard Building",
                        "3A",
                        "Online",
                        "96%",
                      ],
                      [
                        "04/03/21 - 9:32am",
                        "3B - Bathroom",
                        "Bernard Building",
                        "3B",
                        "Online",
                        "100%",
                      ],
                      [
                        "04/03/21 - 9:32am",
                        "3A - Bathroom",
                        "Bernard Building",
                        "3A",
                        "Online",
                        "85%",
                      ],
                    ]}
                  />
                ),
              },
              {
                tabName: "Recently Added",
                tabContent: (
                  <Tasks
                    checkedIndexes={[0]}
                    tasksIndexes={[0, 1]}
                    tasks={website}
                  />
                ),
              },
              {
                tabName: "Alerts",
                tabContent: (
                  <Tasks
                    checkedIndexes={[1]}
                    tasksIndexes={[0, 1, 2]}
                    tasks={server}
                  />
                ),
              },
            ]}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
