import Building from "@material-ui/icons/Domain";
import Dashboard from "@material-ui/icons/Dashboard";
import Notifications from "@material-ui/icons/Notifications";
import PieChartIcon from "@mui/icons-material/PieChart";
import Sensors from "@mui/icons-material/Sensors";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
import UserProfile from "views/UserProfile/UserProfile.js";
import Typography from "views/Typography/Typography.js";
import Icons from "views/Icons/Icons.js";
import NotificationsPage from "views/Notifications/Notifications.js";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Overview",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
  },
  {
    path: "/user",
    name: "Buildings",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Building,
    component: UserProfile,
    layout: "/admin",
  },
  {
    path: "/notifications",
    name: "Notifications",
    rtlName: "إخطارات",
    icon: Notifications,
    component: NotificationsPage,
    layout: "/admin",
  },
  {
    path: "/typography",
    name: "All Sensors",
    rtlName: "طباعة",
    icon: Sensors,
    component: Typography,
    layout: "/admin",
  },
  {
    path: "/icons",
    name: "Reports",
    rtlName: "الرموز",
    icon: PieChartIcon,
    component: Icons,
    layout: "/admin",
  },
];

export default dashboardRoutes;
